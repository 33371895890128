<template>
    <div class="page">
        <el-row :gutter="16">
            <el-col :span="6" v-for="(item, index) in mainOverview" :key="index">
                <div class="main-card" v-if="([0, 2].includes(index)) || !isTraffic_bureau" :style="{ 'grid-template-columns': 'auto ' + (index == 0 ? '60px' : '') + ' 93px' }">
                    <div>
                        <div><span :style="{ 'background': item.color }"></span>今日{{ item.title }}{{ (item.unit == '￥' ? ''
                                    : ' ('+item.unit+')')
                            }}</div>
                        <div><span v-if="item.unit == '￥'" class="cny">{{ item.unit }}</span>
                            <countTo :endVal="item.today | NumberFormatter" :duration="numberDuration"
                                :decimals="[0, 2].includes(index) && item.today < 10000 ? '0' : '2'">
                            </countTo>
                            <span class="unit">{{ item.today | NumberFormatterForUnit }}</span>
                        </div>
                    </div>
                    <div class="progress" v-if="index==0">
                        <el-progress type="circle" :percentage="item.ratio" :width="50" :stroke-width="6"
                            v-if="item.ratio >= 0"></el-progress>
                    </div>
                    <div>
                        <span>昨日</span>
                        <div><span v-if="item.unit == '￥'" class="cny">{{ item.unit }}</span>
                            <countTo :endVal="item.yesterday | NumberFormatter" :duration="numberDuration"
                                :decimals="[0, 2].includes(index) && item.yesterday < 10000 ? '0' : '2'"></countTo>
                            <!-- {{ item.yesterday |
                                NumberFormatter
                        }} -->
                            <span class="unit">{{ item.yesterday | NumberFormatterForUnit }}</span>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="16">
            <el-col :span="4" v-for="(item, index) in subOverview" :key="index">
                <div class="sub-card" @click="toDetail(item)">
                    <div class="mark" :style="{ 'background': item.color }"></div>
                    <img :src="item.icon" />
                    <div>
                        <span>{{ item.title }}</span>
                        <div>
                            <countTo class="count" :endVal="item.value | NumberFormatter" :duration="numberDuration"
                                :decimals="item.title.includes('金额') || item.value > 10000 ? '2' : '0'"></countTo>
                            <span>{{ item.value | NumberFormatterForUnit }}</span>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-card class="box-card">
            <div slot="header" class="box-card-header">
                <div class="box-card-header-title"><span></span>交易笔数趋势</div>
                <el-date-picker v-model="dealCountChartDateArea" type="daterange" align="right" unlink-panels
                    size="small" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </div>
            <div class="chartInfo">
                <div>总交易笔数：</div>
                <div>{{ dealCountChartTotal | NumberFormatter }}
                    <span class="unit">{{ dealCountChartTotal | NumberFormatterForUnit }}</span>
                </div>
            </div>
            <lineChart :obj="dealCountChartObj"></lineChart>
        </el-card>
        <el-card class="box-card" v-if="!isTraffic_bureau">
            <div slot="header" class="box-card-header">
                <div class="box-card-header-title"><span></span>交易金额趋势</div>
                <el-date-picker v-model="dealMoneyChartDateArea" type="daterange" align="right" unlink-panels
                    size="small" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </div>
            <div class="chartInfo">
                <div>总交易金额：</div>
                <div>{{ dealMoneyChartTatal | NumberFormatter }}
                    <span class="unit">{{ dealMoneyChartTatal | NumberFormatterForUnit }}元</span>
                </div>
            </div>
            <lineChart :obj="dealMoneyChartObj"></lineChart>
        </el-card>

    </div>
</template>

<script>
import basePage from '../base/basePage.vue'
import TripWorktableApi from '@/api/TripWorktableApi';
import lineChart from '@/components/chart/echarts-line1-yAxis1.vue'
import { getDateTime } from '@/utils/DateUtils'
import countTo from 'vue-count-to'
export default {
    extends: basePage,
    components: {
        lineChart,
        countTo
    },
    data() {
        return {
            //主要概览数据
            mainOverview: [
                { title: "活跃司机", today: 0, yesterday: 0, ratio: 0, color: "#50CF86", unit: '人' },
                { title: "交易金额", today: 0, yesterday: 0, color: "#FC2D2D", unit: '￥' },
                { title: "交易笔数", today: 0, yesterday: 0, color: "#2C6AFF", unit: "笔" },
                { title: "客单价", today: 0, yesterday: 0, color: "#FF8D03", unit: "￥" },
            ],
            //次要概览数据
            subOverview: [],
            //交易笔数趋势
            dealCountChartObj: {},
            dealCountChartTotal: 0,
            dealCountChartDateArea: [],
            dealCountChartParams: {
                endTimeStr: "",
                startTimeStr: ""
            },
            //交易金额趋势
            dealMoneyChartObj: {},
            dealMoneyChartTatal: 0,
            dealMoneyChartDateArea: [],
            dealMoneyChartParams: {
                endTimeStr: "",
                startTimeStr: ""
            },
            numberDuration: 2000,
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        }
    },
    created() {
        this.setSubOverview();
        this.loadMainOverview();
        this.loadSubOverview();
    },
    mounted() {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        this.dealCountChartDateArea = [start, end];
        if (!this.isTraffic_bureau)
            this.dealMoneyChartDateArea = [start, end];
    },
    filters: {
        NumberFormatter(val) {
            if (isNaN(val))
                return 0;
            else if (val < 10000)
                return val;
            else if (val < 100000000) {
                return (val / 10000).toFixed(2)
            } else {
                return (val / 100000000).toFixed(2)
            }
        },
        NumberFormatterForUnit(val) {
            if (isNaN(val) || val < 10000)
                return "";
            else if (val < 100000000) {
                return '万'
            } else {
                return '亿'
            }
        }
    },
    watch: {
        dealCountChartDateArea(newVal) {
            if (newVal && newVal.length == 2) {
                this.dealCountChartParams.startTimeStr = getDateTime(newVal[0], 'YYYY-MM-DD');
                this.dealCountChartParams.endTimeStr = getDateTime(newVal[1], 'YYYY-MM-DD');
            } else {
                this.dealCountChartParams.startTimeStr = "";
                this.dealCountChartParams.endTimeStr = "";
            }
            this.loadDealCountChart();
        },
        dealMoneyChartDateArea(newVal) {
            if (newVal && newVal.length == 2) {
                this.dealMoneyChartParams.startTimeStr = getDateTime(newVal[0], 'YYYY-MM-DD');
                this.dealMoneyChartParams.endTimeStr = getDateTime(newVal[1], 'YYYY-MM-DD');
            } else {
                this.dealMoneyChartParams.startTimeStr = "";
                this.dealMoneyChartParams.endTimeStr = "";
            }
            this.loadDealMoneyChart();
        }
    },
    methods: {
        loadMainOverview() {
            TripWorktableApi.workbenchTotalFirstLine().then(resp => {
                if (resp.code == 200) {
                    let data = resp.data;
                    this.mainOverview = [
                        { title: "活跃司机", today: data.todayDriverNum, yesterday: data.yesterdayDriverNum, ratio: data.todayDriverRatio, color: "#50CF86", unit: '人' },
                        { title: "交易金额", today: data.todayTransAmt, yesterday: data.yesterdayTransAmt, color: "#FC2D2D", unit: '￥' },
                        { title: "交易笔数", today: data.todayTransCount, yesterday: data.yesterdayTransCount, color: "#2C6AFF", unit: "笔" },
                        { title: "客单价", today: data.todayTransUnitPrice, yesterday: data.yesterdayTransUnitPrice, color: "#FF8D03", unit: "￥" },
                    ]
                }
            }).catch((err) => {
                this.$errorMsg(err);
            });
        },
        loadSubOverview() {
            TripWorktableApi.workbenchTotalSecondLine().then(resp => {
                if (resp.code == 200) {
                    this.setSubOverview(resp.data);
                } else {
                    this.setSubOverview();
                }
            }).catch((err) => {
                this.setSubOverview();
                this.$errorMsg(err);
            });
        },
        setSubOverview(data) {
            this.subOverview = [];
            this.subOverview.push({ title: "司机总量", value: data ? data.driverNum : 0, icon: require('@/assets/img/welcome/driverNum.png'), color: '#fff3e5', path: this.isTraffic_bureau ? "" : this.isCustomer ? 'InquireListPage' : 'TripDriverList' });
            this.subOverview.push({ title: "车辆总量", value: data ? data.carNum : 0, icon: require('@/assets/img/welcome/carNum.png'), color: '#fef8eb', path: this.isTraffic_bureau ? "" : this.isCustomer ? 'CarInquireList' : 'tripCarList' });
            if (this.isAdmin || this.isTraffic_bureau || this.isCustomer)
                this.subOverview.push({ title: "运营公司总量", value: data ? data.companyNum : 0, icon: require('@/assets/img/welcome/companyNum.png'), color: '#edfaf2', path: this.isTraffic_bureau ? "" : "tripCompanyList" });
            if (!this.isMotorcade)
                this.subOverview.push({ title: "车队总量", value: data ? data.motorcadeNum : 0, icon: require('@/assets/img/welcome/motorcadeNum.png'), color: '#e9f0ff', path: this.isTraffic_bureau ? "" : "tripMotorCadeList" });
            this.subOverview.push({ title: "累计交易笔数", value: data ? data.businessNum : 0, icon: require('@/assets/img/welcome/businessNum.png'), color: '#e9f0ff', path: this.isTraffic_bureau ? "TripEventLogListPage1" : (this.isAdmin || this.isCustomer || this.isPlatform) ? "tripEventLog" : "" });
            if (!this.isTraffic_bureau)
                this.subOverview.push({ title: "累计交易金额", value: data ? data.transactionsNum : 0, icon: require('@/assets/img/welcome/transactionsNum.png'), color: '#feeaea', path: (this.isAdmin || this.isCustomer || this.isPlatform) ? "tripEventLog" : "" });
        },
        loadDealCountChart() {
            TripWorktableApi.businessCount(this.dealCountChartParams).then(resp => {
                if (resp.code == 200) {
                    this.dealCountChartTotal = resp.data.businessNum;
                    this.dealCountChartObj = {
                        legend: ["交易笔数"],
                        xAxis: resp.data.list.map(p => p.dateTimeStr),
                        series: resp.data.list.map(p => p.count),
                        xAxisFormatter: (str) => str.substring(5)
                    }
                } else {

                }
            }).catch((err) => {
                this.$errorMsg(err);
            });
        },
        loadDealMoneyChart() {
            TripWorktableApi.transactionsCount(this.dealMoneyChartParams).then(resp => {
                if (resp.code == 200) {
                    this.dealMoneyChartTatal = resp.data.transactionsNum;
                    this.dealMoneyChartObj = {
                        legend: ["交易金额"],
                        xAxis: resp.data.list.map(p => p.dateTimeStr),
                        series: resp.data.list.map(p => p.amount),
                        xAxisFormatter: (str) => str.substring(5),
                        lineColor: "#FC2D2D"
                    }
                }
            }).catch((err) => {
                this.$errorMsg(err);
            });
        },
        toDetail(obj) {
            if (obj && obj.path)
                this.navTo(`/${obj.path}`);
        }
    }
}
</script>

<style lang="scss" scoped>
.page {
    background: #f5f5f5;
    padding: 20px;
    min-height: calc(100vh - 110px);
    margin: -40px -20px -20px -20px;
}

.main-card {
    box-shadow: 1px 1px 3px 1px rgba(164, 164, 164, 0.29);
    background: white;
    border-radius: 8px;
    height: 79px;
    padding: 20px;
    margin-top: 16px;
    display: grid;
    transition: margin-top linear 0.2s;

    >div {
        width: 100%;
        height: 79px;
    }

    >:first-child {
        >:first-child {
            color: #666666;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;

            span {
                display: inline-block;
                width: 6px;
                height: 6px;
                line-height: 20px;
                vertical-align: middle;
                margin: -3px 7px auto auto;
                border-radius: 3px;
            }
        }

        >:last-child {
            display: block;
            font-size: 36px;
            font-weight: bold;
            color: #333333;
            line-height: 47px;
            height: 47px;
            margin-top: 12px;
            span {
                display: inline-block;
            }

            .cny {
                height: 25px;
                font-size: 18px;
                font-weight: 600;
                color: #333333;
                line-height: 25px;
            }

            .unit {
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
                display: inline-block;
                margin-left: 6px;
            }
        }
    }

    >:last-child {
        border-left: 1px solid #F1F1F1;
        height: 56px;
        margin: 11px 0;
        width: 103px;
        min-width: 103px;
        color: rgba($color: #000000, $alpha: 0.4);

        >span {
            font-weight: 400;
            color: #B5B5B5;
            line-height: 17px;
            height: 17px;
            margin: 0px 0 0 16px;
            font-size: 14px;
            display: block;
        }

        >div {
            margin: 12px 0 0 16px;
            height: 21px;
            font-size: 18px;
            font-weight: bold;
            color: #666666;
            line-height: 21px;

            span {
                display: inline-block;
            }

            .unit {
                height: 14px;
                font-size: 10px;
                font-weight: 400;
                color: #666666;
                line-height: 14px;
                display: inline-block;
                margin-left: 2px;
            }

            .cny {
                width: 12px;
                height: 17px;
                font-size: 12px;
                font-weight: 600;
                color: #666666;
                line-height: 17px;
            }
        }
    }
}

.main-card:hover {
    box-shadow: 0px 0px 6px 0px rgba(44, 106, 255, 0.3);
    margin-top: 10px;
}

::v-deep .el-progress {
    margin-top: 25px;
}

.sub-card:hover {
    .mark {
        animation: subCardAnimation 0.8s;
        width: calc((100vw - 120px) / 6);
        height: 104px;
        border-radius: 8px;
        margin: 0px;
        display: block;
    }
}

@keyframes subCardAnimation {
    0% {
        width: 56px;
        height: 56px;
        border-radius: 28px;
        margin: 24px;
        display: block;
    }

    25% {
        height: 104px;
        width: 104px;
        border-radius: 28px;
        margin: 0px;
        display: block;
    }

    28% {
        width: calc((100vw - 120px) / 12);
        height: 104px;
        border-radius: 8px 52px 52px 8px;
        margin: 0px;
        display: block;
    }

    70% {
        width: calc((100vw - 120px) / 6);
        height: 104px;
        border-radius: 8px 52px 52px 8px;
        margin: 0px;
        display: block;
    }

    100% {
        width: calc((100vw - 120px) / 6);
        height: 104px;
        border-radius: 8px;
        margin: 0px;
        display: block;
    }
}

@keyframes subCardAnimation1 {
    0% {
        width: calc((100vw - 120px) / 6);
        height: 104px;
        border-radius: 8px;
        margin: 0px;
        display: block;
    }

    30% {
        width: calc((100vw - 120px) / 6);
        height: 104px;
        border-radius: 8px 52px 52px 8px;
        margin: 0px;
        display: block;
    }

    72% {
        width: calc((100vw - 120px) / 12);
        height: 104px;
        border-radius: 8px 52px 52px 8px;
        margin: 0px;
        display: block;
    }

    75% {
        height: 104px;
        width: 104px;
        border-radius: 28px;
        margin: 0px;
        display: block;
    }

    100% {
        width: 56px;
        height: 56px;
        border-radius: 28px;
        margin: 24px;
        display: block;
    }
}

.sub-card {
    height: 104px;
    background: #FFFFFF;
    box-shadow: 1px 1px 3px 1px rgba(164, 164, 164, 0.29);
    border-radius: 8px;
    margin: 16px 0 0 0;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    position: static;

    .mark {
        width: 56px;
        height: 56px;
        border-radius: 28px;
        opacity: 0.4;
        position: absolute;
        margin: 24px;
        z-index: 0;
        animation: subCardAnimation1 0.8s;
    }


    img {
        width: 56px;
        height: 56px;
        margin: 24px 16px 24px 24px;
        z-index: 1;
    }

    div {
        margin: 21px 0;
        z-index: 1;

        :first-child {
            display: block;
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
        }

        :last-child {
            margin-top: 4px;

            .count {
                height: 33px;
                font-size: 24px;
                font-weight: 500;
                color: #333333;
                line-height: 33px;
                margin-left: 0px;
            }

            span {
                height: 20px;
                display: inline-block;
                font-size: 14px;
                font-weight: 400;
                color: #B5B5B5;
                line-height: 20px;
                margin-left: 4px;
            }
        }
    }
}

::v-deep .box-card {
    margin-top: 16px;
    border-radius: 8px;

    .el-card__header {
        border: none;
        padding: 15px 20px;
    }

    .el-card__body {
        padding: 0 20px 20px 20px;
    }

    .box-card-header {
        display: flex;
        justify-content: space-between;
        line-height: 32px;

        .box-card-header-title {
            height: 22px;
            font-size: 16px;
            font-weight: 600;
            color: #333333;
            line-height: 22px;
            margin: 5px 0;

            >span {
                width: 4px;
                height: 12px;
                background: #032EFF;
                border-radius: 2px;
                display: inline-block;
                margin-right: 8px;
            }
        }
    }

    .chartInfo {
        display: flex;
        justify-content: flex-start;

        >div {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #B5B5B5;
            line-height: 20px;
        }

        :last-child {
            height: 21px;
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            line-height: 21px;
            letter-spacing: 2px;

            span {
                height: 17px;
                font-size: 12px;
                font-weight: 400;
                color: #333333;
                line-height: 17px;
            }
        }
    }
}
</style>