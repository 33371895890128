import * as AppHttpKit from '@/utils/http/AppHttpKit';
/**
 * 工作台(新)
 */
const TripWorktableApi = {
    workbenchTotalFirstLine: () => AppHttpKit.get('/v1/index/workbenchTotalFirstLine'),//首行汇总数据
    workbenchTotalSecondLine: (data) => AppHttpKit.postJSON('/v1/index/workbenchTotalSecondLine'),//第二行汇总数据
    businessCount: (data) => AppHttpKit.postJSON('/v1/index/businessCount', data),//数据分析-交易笔数
    transactionsCount: (data) => AppHttpKit.postJSON('/v1/index/transactionsCount', data),//数据分析-交易金额

}
export default TripWorktableApi;