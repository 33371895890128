<!--
折线图1条线，1个y轴
obj.legend		图例->数组->["交易金额"]
obj.xAxis 横轴坐标数据->数组->["2021-09-01", "2021-09-02", "2021-09-03"]
obj.yAxisName  增长总量
obj.series 纵坐标绿色线数据->数组->[1, 2, 3]
obj.splitNumber 折线数量
obj.xAxisFormatter 横轴坐标数据格式化
obj.lineColor 折线颜色
-->
<template>
  <div>
    <div class="echartsLine" ref="echartsLine"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    obj: {
      type: Object,
      default: {},
    },
  },
  watch: {
    obj(value) {
      console.log(value);
      this.obj = value;
      this.init();
    },
  },
  methods: {
    init() {
      let that = this;
      let splitNumber = 5;
      if (that.obj.splitNumber && typeof that.obj.splitNumber == "number") {
        // that.$refs.echartsLine.style.height =
        //   that.obj.splitNumber * 40 + 100 + "px";
        splitNumber = that.obj.splitNumber;
      }
      let xAxis = that.obj.xAxis;
      if (that.obj.xAxisFormatter) {
        xAxis = [];
        that.obj.xAxis.forEach(p => xAxis.push(that.obj.xAxisFormatter(p)));
      }
      let myChart = this.$echarts.init(this.$refs.echartsLine);
      let option = {
        legend: {
          show: false,
          data: that.obj.legend,
          top: 26,
          right: 24,
          overview: "unset",
          itemWidth: 30,
          itemHeight: 12,
          textStyle: {
            color: "#999999",
            fontSize: 12,
          },
        },
        tooltip: {
          // trigger: "axis",
          // borderWidth: "156px",
          // backgroundColor: "#fff",
          // borderColor: "#fff",
          // textStyle: {
          //   color: "#7D7D7D",
          // },
          // lineStyle: {
          //   color: "red",
          // },
          extraCssText: " border-radius: 8px;",
          padding: 0,
          formatter: function (e) {//($color: #000000, $alpha: 0.4);
            let color = that.obj.lineColor ?? "#2F54EB";
            let rgba = parseInt("0x" + color.slice(1, 3)) + "," + parseInt("0x" + color.slice(3, 5)) + "," + parseInt("0x" + color.slice(5, 7));
            let title = `
            <div class="tooltip-cus" style="box-shadow: 0px 3px 9px 0px rgba(${rgba},0.25) !important;">
              <div class="tip-item">${that.obj.xAxis[e.dataIndex]
              }</div>
              <span>${e.marker + that.obj.legend[0]}：${that.obj.series[e.dataIndex]
              }</span>
            </div>
            `;
            return title;
          }
        },
        xAxis: {
          type: "category",
          offset: 14,
          data: xAxis,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#EAEDF7",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#B5B5B5", //更改坐标轴文字颜色
              fontSize: 12, //更改坐标轴文字大小
            },
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: [
          {
            name: that.obj.yAxisName,
            splitNumber: splitNumber,
            type: "value",
            axisLine: {
              //  刻度线
              show: false,
            },
            axisTick: {
              //  刻度
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#B5B5B5", //更改坐标轴文字颜色
                fontSize: 12, //更改坐标轴文字大小
              },
            },
            nameTextStyle: {
              fontSize: 14,
              color: "#636E95",
            },
            splitLine: {
              //  分割线
              lineStyle: {
                show: false,
                color: "#EAEDF7",
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        grid: {
          top: 20,
          left: 0,
          right: 0,
          bottom: 20,
          containLabel: true,
        },
        axisPointer: {
          lineStyle: {
            width: 1,
            color: "#e6e6e6", //竖线
            type: "dashed",
          },
        },
        series: [
          {
            symbol: "circle",
            data: that.obj.series,
            name: that.obj.legend[0],
            type: "line",
            symbolSize: 8,
            smooth: true,
            labelLine: {
              show: false,
            },
            lineStyle: {
              color: that.obj.lineColor ?? "#2F54EB",
              width: 4
            },
            itemStyle: {
              color: that.obj.lineColor ?? "#2F54EB",
              borderColor: "#FFFFFF",
              borderWidth: 2,
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: that.obj.lineColor ?? '#2F54EB' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(255,255,255, 0)', // 100% 处的颜色
                }],
                global: false // 缺省为 false
              },
              opacity: 0.4,
            },
          },
        ],
      };
      myChart.setOption(option);
      // 自适应
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
  },
  mounted() { },
};
</script>

<style lang="scss">
.echartsLine {
  width: 100%;
  height: 100%;
  min-height: 300px;
}
</style>
